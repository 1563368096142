import React, { useEffect, useRef } from "react";
import * as THREE from "three";

const vertexShader = `
    attribute float size;
    varying vec3 vColor;
    void main() {
        vColor = color;
        vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
        gl_PointSize = size * (300.0 / -mvPosition.z);
        gl_Position = projectionMatrix * mvPosition;
    }
`;

const fragmentShader = `
    varying vec3 vColor;
    void main() {
        float dist = length(gl_PointCoord - vec2(0.5, 0.5));
        if (dist > 0.5) {
            discard;
        }
        gl_FragColor = vec4(vColor, 1.0);
    }
`;

const Logo = () => {
  const mountRef = useRef(null);

  useEffect(() => {
    // Set up the scene, camera, and renderer
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    // Create the geometry and material for the point cloud
    const geometry = new THREE.BufferGeometry();
    const points = [];
    const colors = [];
    const sizes = [];
    const velocities = [];
    const originalPositions = [];
    const driftOffsets = [];
    const pointCount = 10000;
    const hoverRadius = 10; // Radius for the hover effect
    const driftFactor = 1; // Controls the amount of drift

    for (let i = 0; i < pointCount; i++) {
      const theta = Math.random() * 2 * Math.PI;
      const phi = Math.acos(Math.random() * 2 - 1);
      const radius = 30 + Math.random() * 20; // Make the cloud soft-edged
      const x = radius * Math.sin(phi) * Math.cos(theta);
      const y = radius * Math.sin(phi) * Math.sin(theta);
      const z = radius * Math.cos(phi);
      points.push(x, y, z);
      originalPositions.push(x, y, z);

      const color = new THREE.Color(1, 1, 1);
      colors.push(color.r, color.g, color.b);

      sizes.push(1);

      velocities.push(0, 0, 0);
      driftOffsets.push(Math.random() * Math.PI * 2, Math.random() * Math.PI * 2, Math.random() * Math.PI * 2);
    }

    geometry.setAttribute("position", new THREE.Float32BufferAttribute(points, 3));
    geometry.setAttribute("color", new THREE.Float32BufferAttribute(colors, 3));
    geometry.setAttribute("size", new THREE.Float32BufferAttribute(sizes, 1));

    const material = new THREE.ShaderMaterial({
      vertexShader,
      fragmentShader,
      transparent: true,
      vertexColors: true,
    });

    const pointCloud = new THREE.Points(geometry, material);
    scene.add(pointCloud);

    camera.position.z = 100;

    // Interaction
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();
    const mousePosition = new THREE.Vector3();

    const onPointerMove = (event) => {
      let clientX, clientY;

      if (event.touches) {
        // Touch event
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else {
        // Mouse event
        clientX = event.clientX;
        clientY = event.clientY;
      }

      mouse.x = (clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObject(pointCloud);
      if (intersects.length > 0) {
        mousePosition.copy(intersects[0].point);
      }
    };

    window.addEventListener("mousemove", onPointerMove, false);
    window.addEventListener("touchmove", onPointerMove, false);

    let time = 0;
    const animate = () => {
      requestAnimationFrame(animate);
      time += 0.01;

      const positions = geometry.attributes.position.array;

      for (let i = 0; i < pointCount; i++) {
        const idx = i * 3;
        const pointPosition = new THREE.Vector3(positions[idx], positions[idx + 1], positions[idx + 2]);
        const distance = pointPosition.distanceTo(mousePosition);

        if (distance < hoverRadius) {
          const direction = new THREE.Vector3().subVectors(pointPosition, mousePosition).normalize();
          const force = ((hoverRadius - distance) / hoverRadius) * 2; // Reduced force
          velocities[idx] = direction.x * force;
          velocities[idx + 1] = direction.y * force;
          velocities[idx + 2] = direction.z * force;
        }

        // Apply velocity and reset points to original positions with a bouncing effect
        positions[idx] += velocities[idx];
        positions[idx + 1] += velocities[idx + 1];
        positions[idx + 2] += velocities[idx + 2];

        // Apply drift
        const driftX = Math.sin(time + driftOffsets[idx]) * driftFactor;
        const driftY = Math.cos(time + driftOffsets[idx + 1]) * driftFactor;
        const driftZ = Math.sin(time + driftOffsets[idx + 2]) * driftFactor;

        const dx = (originalPositions[idx] + driftX - positions[idx]) * 0.05;
        const dy = (originalPositions[idx + 1] + driftY - positions[idx + 1]) * 0.05;
        const dz = (originalPositions[idx + 2] + driftZ - positions[idx + 2]) * 0.05;

        positions[idx] += dx;
        positions[idx + 1] += dy;
        positions[idx + 2] += dz;

        velocities[idx] *= 0.9;
        velocities[idx + 1] *= 0.9;
        velocities[idx + 2] *= 0.9;
      }

      geometry.attributes.position.needsUpdate = true;
      renderer.render(scene, camera);
    };

    animate();

    // Handle window resize
    const onWindowResize = () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    };

    window.addEventListener("resize", onWindowResize, false);

    return () => {
      // Clean up on component unmount
      window.removeEventListener("mousemove", onPointerMove);
      window.removeEventListener("touchmove", onPointerMove);
      window.removeEventListener("resize", onWindowResize);
      mountRef.current.removeChild(renderer.domElement);
    };
  }, []);

  return <div ref={mountRef}></div>;
};

export default Logo;
